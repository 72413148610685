// resources/js/app.js

import './bootstrap';
import 'bootstrap/js/dist/dropdown';
import '../css/app.css';
import '../css/chat.css';
import '../css/global.css';
import '../css/fonts.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import axios from './utils/axiosConfig';  
import { Inertia } from '@inertiajs/inertia';

// Importiere den CookieBanner
import CookieBanner from './Components/CookieBanner.vue';

const appName = 'My StoryPro AI';

Inertia.on('success', (event) => {
    const csrfToken = event.detail.page.props.csrfToken;
    if (csrfToken) {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    }
});

document.addEventListener('DOMContentLoaded', () => {
    createInertiaApp({
        title: (title) => {
            const pageTitles = {
                'Auth/Login': 'Login',
                'Auth/Register': 'Register',
                'Chats/Index': 'Chats',
            };
        
            const pageName = title || '';
        
            const pageTitle = pageTitles[pageName] || title;
        
            if (pageTitle && pageTitle !== appName) {
                return `${appName} | ${pageTitle}`;
            } else {
                return appName;
            }
        },
        resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
        setup({ el, App, props, plugin }) {
            const vueApp = createApp({ 
                render: () => h('div', [
                    h(App, props),
                    h(CookieBanner) // Füge den CookieBanner hier hinzu
                ]) 
            })
            .use(plugin)
            .use(ZiggyVue);

            // CSRF-Token aus den Props holen
            const csrfToken = props.initialPage.props.csrfToken;



            if (csrfToken) {
                axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
              }
              

            vueApp.config.globalProperties.$axios = axios;

            vueApp.mount(el);
        },
        progress: false,
    });

    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker.register('/sw.js').then(registration => {
                //console.log('ServiceWorker registration successful with scope: ', registration.scope);
            }, err => {
                console.log('ServiceWorker registration failed: ', err);
            });
        });
    }
});
